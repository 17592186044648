import { CompanyInterface } from "@/constants/companysetup.constants";
import useStorage from "@/hooks/useStorage";
import { removeCompany, setCompany } from "../features/company";
import { TENANT } from "@/constants/common.constants";

const { getItem, setItem, removeItem } = useStorage();

/**
 * Saves company information to storage and dispatches an action.
 *
 * @param _key - The key used for storing the company information.
 * @param companyInfo - The information of the company to be saved.
 * @param dispatch - The function to dispatch actions.
 * @param callback - A function to be called after the operation is completed.
 */
export const saveCompany = async (
  _key: string,
  companyInfo: CompanyInterface,
  dispatch: Function,
  callback: Function,
) => {
  try {
    const storedCompanies = await getCompanyInfo(_key);
    const companies = [companyInfo];

    let companiesInfo: CompanyInterface[] = storedCompanies ? JSON.parse(storedCompanies) : [];

    setItem(TENANT, JSON.stringify(companyInfo.TenantId));

    const index = companiesInfo.findIndex((item) => item.CompanyId === companyInfo.CompanyId);

    if (companyInfo.rememberCompany) {
      if (index === -1) {
        companiesInfo.push(companyInfo);
      }

      companiesInfo.forEach((list) => (list.isActive = list.CompanyId === companyInfo.CompanyId));

      setItem(_key, JSON.stringify(companiesInfo));
    } else {
      setItem(_key, JSON.stringify(companies));
    }

    dispatch(setCompany(companyInfo));
    callback({ isCreated: true, error: false, errorMessage: "" });
  } catch (error) {
    // Handle error here
  }
};

/**
 * Gets company information from storage.
 *
 * @param _key - The key used for storing the company information.
 * @returns The stored company information, or null if not found.
 */
export const getCompanyInfo = async (_key: string) => {
  try {
    return await getItem(_key);
  } catch (error) {
    // Handle error here
    return null;
  }
};

/**
 * Removes the active company from storage and dispatches an action.
 *
 * @param _key - The key used for storing the company information.
 * @param dispatch - The function to dispatch actions.
 * @param callback - A function to be called after the operation is completed.
 */
export const removeActiveCompany = async (_key: string, dispatch: Function, callback: Function) => {
  try {
    // @ts-ignore
    let companiesInfo: CompanyInterface[] = JSON.parse(await getCompanyInfo(_key));

    companiesInfo.forEach((list) => (list.isActive = false));

    setItem(_key, JSON.stringify(companiesInfo));

    dispatch(removeCompany({}));
    callback({ isCreated: true, error: false, errorMessage: "" });
  } catch (error) {
    // Handle error here
  }
};

/**
 * Removes company information from storage based on the specified type.
 *
 * @param _key - The key used for storing the company information.
 * @param type - The type of removal operation to perform.
 */
export const removeCompanyInfo = async (_key: string, type: string) => {
  try {
    switch (type) {
      case "all":
        return await removeItem(_key);

      default:
        // Handle unsupported type
        break;
    }
  } catch (error) {
    // Handle error here
  }
};
