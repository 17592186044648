import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { server } from "@/config/server";
import getHeaders from "./headers";


export const companyApiSlice = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getUserCompany: builder.query<any, any>({
      query: (userId) => {
        return {
          url: `/usertenants`,
          method: "POST",
          body: {
            userId: userId,
          },
        };
      },
      providesTags: [{ type: "Company", id: "companies" }],
    }),
    getCompany: builder.query<any, any>({
      query: (tenantId) => {
        return {
          url: `/company/${tenantId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Company", id: "companies" }],
    }),

    //Updates company details
    updateCompany: builder.mutation<any, any>({
      query: (newDetails) => {
        return {
          url: `/company`,
          method: "PUT",
          body: newDetails
        };
      },
      invalidatesTags: [{ type: "Company", id: "companies" }],
    }),
  }),
});

export const { useGetUserCompanyQuery, useGetCompanyQuery, useLazyGetCompanyQuery, useUpdateCompanyMutation } = companyApiSlice;
